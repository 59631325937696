<template>
  <div>
    <div class="row d-flex justify-content-end mr-1 mb-3">
      <b-btn variant="warning" href="#/master-data/mitra" class="ml-auto text-white" style="width: 7em;">Kembali</b-btn>
      <b-btn variant="primary" :href="'#/master-data/mitra/' + $route.params.id + '/edit'" class="ml-2" style="width: 7em;">Edit</b-btn>
    </div>
    <b-card header-tag="header">
      <template #header>
        <b-row>
          <b-col cols="10">
            <h5 class="mb-0">{{$route.name}}</h5>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <!-- <template v-for="(m, index) in model">
          <b-col :key="index" cols="4" v-if="(typeof m.disabled === 'undefined' || !m.disabled) && m.type !== 'hidden'">
            <detail-group-input :key="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :value="m.value" :id="index">
              <template slot="content_photo" slot-scope="defaultSlotScope">
                <img :src="defaultSlotScope.colData" width="200" />
              </template>
            </detail-group-input>
          </b-col>
        </template> -->
        <b-col cols="2">
          <b-img rounded="circle" alt="Circle image" :src="model.photo.value" width="150" height="150" class="border" />
        </b-col>
        <b-col cols="10">
          <b-row class="pl-5">
            <b-col class="heading-color">
              <b-row class="mt-2">
                <b-col cols="6">Nama</b-col>
                <b-col cols="6">: {{ model.name.value }}</b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="6">Alamat</b-col>
                <b-col cols="6">: {{ model.address.value }}</b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="6">Nomor Handpone</b-col>
                <b-col cols="6">: {{ model.phone_number.value }}</b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="6">Status</b-col>
                <b-col cols="6">: <b-badge variant="success" v-if="model.is_active.value == 1">Aktif</b-badge><b-badge variant="danger" v-else>Tidak Aktif</b-badge></b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="6">Deskripsi</b-col>
                <b-col cols="6">: {{ model.bio.value }}</b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <div class="my-3">
            <b-button :to="'/order?user_id=' + this.$route.params.id" variant="primary" v-b-tooltip.hover.right title="Click for more order detail!">Detail Order</b-button>
          </div>
        </b-col>
      </b-row> -->
    </b-card>
  </div>
</template>

<script>
import { setModelValue } from '@/_helpers'
import detailGroupInput from '@/components/DetailGroupInput.vue'
import Mitra from '@/models/Mitra.js'

const mitraModel = new Mitra()

export default {
  components: {
    detailGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (val !== undefined) {
        this.getDetail()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      model: mitraModel.init()
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.isLoading = true
      mitraModel.find(this.$route.params.id).then(resp => {
        this.isLoading = false
        setModelValue(this.model, resp)
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
        this.isLoading = false
      })
    }
  }
}
</script>